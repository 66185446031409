/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: 'Glober';
  src: url('/assets/fonts/GloberRegular.woff2') format('woff2'),
      url('/assets/fonts/GloberRegular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html, body {
  font-family: 'Glober', sans-serif !important;
  overflow-x: hidden;
}

.bg-primary {
  background-color: #232f61 !important;
}

.color-primary {
  color: #232f61 !important;
}

.btn-primary {
  border: #232f61 !important;
  background-color: #232f61 !important;
  color: white;
}

.bg-secondary {
  background-color: #01994f !important;
}

.color-secondary {
  color: #01994f !important;
}

.btn-secondary {
  border: #01994f !important;
  border-radius: 0;
  background-color: #01994f !important;
  color: white;
}

.btn-cancel {
  border: lightgray !important;
  border-radius: 0;
  background-color: gray !important;
  color: white;
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: #01994f;
  background-color: #01994f;
  align-self: center!important;
}

/* Ocultar el checkbox original */
.form-check-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.form-check-input:disabled {
  opacity:0 !important;
}

.form-check-label {
  display: inline-block;
  width:  1rem;
  height:  1rem;
  background-color: #e9ecef;
  border-radius: 50%;
  border: 1px solid #01994f;
  transition: background-color 0.3s;
  position: relative;
  cursor: pointer;
}

/* Fondo verde y check cuando está marcado */
.form-check-input:checked + .form-check-label {
  background-color: #01994f; /* Fondo verde */
  border-color: #01994f;
}

/* Icono de check en el centro cuando está marcado */
.form-check-input:checked + .form-check-label::after {
  content: '';
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 8px;
  height: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Quitar el icono de check cuando no está marcado */
.form-check-input:not(:checked) + .form-check-label::after {
  content: '';
}

.form-check-input:focus:not(.is-invalid) + .form-check-label {
  box-shadow: 0 0 0 .20rem #14a15d38;
}

.form-check-input.is-invalid + .form-check-label {
  border-color: red;
}

.form-check-input.is-invalid:focus + .form-check-label {
  box-shadow: 0 0 0 .20rem rgba(255, 0, 0, 0.24);
}

label{
  margin-bottom: .5rem !important;
}

.toast-top-right {
  position: fixed;
  top: 0.5rem;
  right: 1.5rem;
  z-index: 1055;
}

.bg-container-b{
  margin-bottom: 2rem;
  background-color: #e9ecef;
}
